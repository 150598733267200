const FieldComponent = Formio.Components.components.field;

class HijriDateComponent extends FieldComponent {
    /**
     * This is the default schema of your custom component. It will "derive"
     * from the base class "schema" and extend it with its default JSON schema
     * properties. The most important are "type" which will be your component
     * type when defining new components.
     *
     * @param extend - This allows classes deriving from this component to 
     *                 override the schema of the overridden class.
     */
    static schema(...extend) {
        return FieldComponent.schema({
            type: 'hijridate',
            label: 'Hijri Date',
            key: 'hijriDate',
            allowInput: true
        });
    }
    
    /**
     * This is the Form Builder information on how this component should show
     * up within the form builder. The "title" is the label that will be given
     * to the button to drag-and-drop on the buidler. The "icon" is the font awesome
     * icon that will show next to it, the "group" is the component group where
     * this component will show up, and the weight is the position within that
     * group where it will be shown. The "schema" field is used as the default
     * JSON schema of the component when it is dragged onto the form.
     */
    static get builderInfo() {
        return {
          title: 'Hijri Date',
          icon: 'calendar',
          group: 'advanced',
          documentation: '/userguide/#date',
          weight: 0,
          schema: HijriDateComponent.schema()
        };
    }
    
    /**
     * Called when the component has been instantiated. This is useful to define
     * default instance variable values.
     *
     * @param component - The JSON representation of the component created.
     * @param options - The global options for the renderer
     * @param data - The contextual data object (model) used for this component.
     */
    constructor(component, options, data) {
        super(component, options, data);
        this.id = `hijriDate-${Math.random().toString(36).slice(2)}`;
        this.readonly = component.disabled;
        this.height = 257;
        this.headerHeight = 60;
        this.halfWidth = 308/2;

        var dateRanges = ''
        try{
            dateRanges = component.datePicker.disable;
        }
        catch(error){}

        // Split the input string by comma to separate different ranges
        const rangeList = dateRanges.split(',');

        this.dateRangeArray = [];

        // Loop through each range and split further if needed
        for (let i = 0; i < rangeList.length; i++) {
            let ranges = rangeList[i].trim().split(' - ');
            let start = ranges[0].trim();
            let end = ranges[1] ? ranges[1].trim() : start;

            this.dateRangeArray.push({
                from: start,
                to: end
            });
        }
        this.cal = new Calendar(this.id, true, 0, true, true, undefined, undefined, undefined, this.dateRangeArray);
    }
    
    /**
     * Called immediately after the component has been instantiated to initialize
     * the component.
     */
    init() {
        super.init();
    }
    
    // /**
    //  * For Input based components, this returns the <input> attributes that should
    //  * be added to the input elements of the component. This is useful if you wish
    //  * to alter the "name" and "class" attributes on the <input> elements created
    //  * within this component.
    //  *
    //  * @return - A JSON object that is the attribute information to be added to the
    //  *           input element of a component.
    //  */
    // get inputInfo() {
    //     const info = super.inputInfo;
    //     return info;
    // }
    
    /**
     * This method is used to render a component as an HTML string. This method uses
     * the template system (see Form Templates documentation) to take a template
     * and then render this as an HTML string.
     *
     * @param content - Important for nested components that receive the "contents"
     *                  of their children as an HTML string that should be injected
     *                  in the {{ content }} token of the template.
     *
     * @return - An HTML string of this component.
     */
    render(content) {
        return super.render(`<div ref="hijriDate" id="${this.id}" style="cursor: default"><div class="input-group"><input style="cursor: default" type="text" class="form-control form-control input" ref="hijriInput" ${this.component.allowInput ? "" : "readonly"}><div ref="suffix" class="input-group-append"><span class="input-group-text"><i style="" class="fa fa-calendar" ref="icon"></i></span></div></div></div>`);
    }
    
    /**
     * The attach method is called after "render" which takes the rendered contents
     * from the render method (which are by this point already added to the DOM), and
     * then "attach" this component logic to that html. This is where you would load
     * any references within your templates (which use the "ref" attribute) to assign
     * them to the "this.refs" component variable (see comment below).
     *
     * @param - The parent DOM HtmlElement that contains the component template.
     *
     * @return - A Promise that will resolve when the component has completed the
     *           attach phase.
     */
    attach(element) {
        /**
         * This method will look for an element that has the 'ref="customRef"' as an
         * attribute (like <div ref="customRef"></div>) and then assign that DOM
         * element to the variable "this.refs". After this method is executed, the
         * following will point to the DOM element of that reference.
         *
         * this.refs.customRef
         *
         * For DOM elements that have multiple in the component, you would make this
         * say 'customRef: "multiple"' which would then turn "this.refs.customRef" into
         * an array of DOM elements.
         */
        this.loadRefs(element, {
            hijriDate: 'single'
        });
        this.loadRefs(element.querySelector('input'), {
            hijriInput: 'single'
        });

        this.el = document.getElementById(this.id);
        document.body.appendChild(this.cal.getElement());

        if (!this.readonly) {
            this.readonly = $(this.el).parents('.formio-form').hasClass('formio-read-only');
        }
        
        if (this.readonly) {
            this.el.querySelector('input') && (this.el.querySelector('input').setAttribute('readonly', 'readonly'));
        }

        this.hideCal = (e) => {
            if (!!e && !!this.findParent(e.target)) return;
            this.cal.hide();
        };
        /**
         * It is common to attach events to your "references" within your template.
         * This can be done with the "addEventListener" method and send the template
         * reference to that object.
         */
        this.addEventListener(this.refs.hijriDate, 'click', (e) => {
            if (this.readonly) return;
            /**
             * We want to position the hajri calendar based on the space found.
             * 1- get co-ordinates of the date field 
             */
            const compRect = $(`div[id=${this.id}]`)[0].getBoundingClientRect();
            /**
             * 2- calculate if there is enoght space to draw the calendar above the field
             *  - compRect.top : the position of the field in the browser
             *  - height: the height of the calendar
             *  - headerHeight: the height of frappe toolbar 
             * 3 - we subtract these values to get the start position of the calendar above the field after drawing it
             */
            const topSpace = compRect.top - this.height - this.headerHeight;
            const element = this.cal.getElement();

            /**
             * 4- if the space left greater than zero we can position the calendar above the field else we position it below.
             * 5- we calculate the start position of the calendar by summing:
             *  - topspace: the start position of the calendar
             *  - headerHeight: the height of the header we removed before
             *  - window.scrollY: the value of the space scrolled from top , if were any.
             */
            if(topSpace > 0 ){
                element.style.top = topSpace + this.headerHeight + window.scrollY + 'px';
                element.classList.replace('arrowTop','arrowBottom');

            }else{
                element.style.top = compRect.top + window.scrollY + compRect.height+ 10 + 'px';
                element.classList.replace('arrowBottom','arrowTop');
            }
            /**
             * 6- calculate the  x start position of the calender:
             *   - compRect: the x start position of the date field
             *   - and sum half of the width of the datefield to center the calendar position
             *   - and sub half of the width of the calendar to center its position
             */
            element.style.left = compRect.x + (compRect.width/2) - this.halfWidth + 'px';
            this.cal.show();
        });

        if(!this.component.allowInput){
            this.addEventListener(this.refs.hijriDate, 'keydown', (e) => {
                e.preventDefault();
            });
        }

        this.addEventListener(this.refs.hijriDate, 'input', (e) => {
            const val = this.el.querySelector('input').value;
            this.setValue(val);
            if (val.indexOf('/') !== -1) {
                const [day, month, year] = val.split('/');
                this.cal.setDate(year, month, day);
                const allDays = $('span.flatpickr-day:contains("'+parseInt(day)+'")');
                const nonPrevMonthDays = allDays.filter(":not(.prevMonthDay)");
                const firstNonPrevMonthDay = nonPrevMonthDays.first();
                firstNonPrevMonthDay.addClass("selected");
            }
        });

        document.addEventListener('click', this.hideCal);

        this.cal.callback = () => {
            this.el.querySelector('.input').value = this.getFormattedDate();
            this.updateValue(this.getFormattedDate());
        };

        this.getValue() && this.setValue(this.getValue());

        return super.attach(element);
    }

    getFormattedDate(day, month, year) {
        if (day == void 0 || month == void 0 || year == void 0) {
            let date = this.cal.getDate();
            day = date.getDate(), month = date.getMonth() + 1, year = date.getFullYear();
        }
        return ((day < 10)?'0':'') + day + '/' + ((month < 10?'0':'')) + month + '/' + year;
    }

    findParent(el) {
        if (!el) {
            return null;
        } else if (!!el.parentNode && el.parentNode.id === this.id) {
            return el.parentNode;
        }
        return this.findParent(el.parentNode);
    }

    /**
     * Called when the component has been detached. This is where you would destroy
     * any other instance variables to free up memory. Any event registered with
     * "addEventListener" will automatically be detached so no need to remove them
     * here. 
     *
     * @return - A Promise that resolves when this component is done detaching.
     */
    detach() {
        document.removeEventListener('click', this.hideCal);
        return super.detach();
    }
 
    /**
     * Called when the component has been completely "destroyed" or removed form the
     * renderer.
     *
     * @return - A Promise that resolves when this component is done being destroyed.
     */
    destroy() {
        this.cal.destroy();
        return super.destroy();
    }
 
    /**
     * A very useful method that will take the values being passed into this component
     * and convert them into the "standard" or normalized value. For exmample, this
     * could be used to convert a string into a boolean, or even a Date type.
     *
     * @param value - The value that is being passed into the "setValueAt" method to normalize.
     * @param flags - Change propogation flags that are being used to control behavior of the
     *                change proogation logic.
     *
     * @return - The "normalized" value of this component.
     */
    normalizeValue(value, flags = {}) {
        return super.normalizeValue(value, flags);
    }
    
    /**
     * Returns the value of the "view" data for this component.
     *
     * @return - The value for this whole component.
     */
    getValue() {
        return super.getValue();
    }
    
    /**
     * Much like "getValue", but this handles retrieving the value of a single index
     * when the "multiple" flag is used within the component (which allows them to add
     * multiple values). This turns a single value into an array of values, and this
     * method provides access to a certain index value.
     *
     * @param index - The index within the array of values (from the multiple flag) 
     *                that is getting fetched.
     *
     * @return - The view data of this index.
     */
    getValueAt(index) {
        return super.getValueAt(index);
    }

    fullDateToFormattedDate(value, flags) {
        var vals = /^[a-zA-Z]+, ([0-9]{1,2}) ([a-zA-Z'-]+) ([0-9]{4}) H$/.exec(value);
        if (!!value && vals && vals.length === 4) {
            var year = parseInt(vals[3]), month = HijriDate.monthNames.indexOf(vals[2]), day = parseInt(vals[1]);
            if (!isNaN(year) && !isNaN(day) && (month || month === 0)) {
                value = this.getFormattedDate(day, month + 1, year);
                this.cal.setDate(year, month, day);
                this.el.querySelector('.input') && (this.el.querySelector('.input').value = value);
                this.updateValue(value);
                return super.setValue(value, flags);
            }
        }
    }
    
    /**
     * Sets the value of both the data and view of the component (such as setting the
     * <input> value to the correct value of the data. This is most commonly used
     * externally to set the value and also see that value show up in the view of the
     * component. If you wish to only set the data of the component, like when you are
     * responding to an HTML input event, then updateValue should be used instead since
     * it only sets the data value of the component and not the view. 
     *
     * @param value - The value that is being set for this component's data and view.
     * @param flags - Change propogation flags that are being used to control behavior of the
     *                change proogation logic.
     *
     * @return - Boolean indicating if the setValue changed the value or not.
     */
    setValue(value, flags = {}) {
        if (value) {
            value = value.replace(/-/g, '/');
        }
        var vals = /^(0[1-9]|[12][0-9]|30)\/(0[1-9]|1[0-2])\/\d{4}$/.exec(value);
        if (!!value && vals && vals.length === 4) {
            var year = parseInt(vals[3]), month = parseInt(vals[2]), day = parseInt(vals[1]);
            if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
                this.cal.setDate(year, month-1, day);
                this.el.querySelector('.input') && (this.el.querySelector('.input').value = value);
                this.updateValue(value);
                return super.setValue(value, flags);
            }
        } else {
            this.fullDateToFormattedDate(value, flags);
        }
        return false;
    }
    
    /**
     * Sets the value for only this index of the component. This is useful when you have
     * the "multiple" flag set for this component and only wish to tell this component
     * how the value should be set on a per-row basis.
     *
     * @param index - The index within the value array that is being set.
     * @param value - The value at this index that is being set.
     * @param flags - Change propogation flags that are being used to control behavior of the
     *                change proogation logic.
     *
     * @return - Boolean indiciating if the setValue at this index was changed.
     */
    setValueAt(index, value, flags = {}) {
        return super.setValueAt(index, value, flags);
    }
    
    /**
     * Similar to setValue, except this does NOT update the "view" but only updates
     * the data model of the component.
     *
     * @param value - The value of the component being set.
     * @param flags - Change propogation flags that are being used to control behavior of the
     *                change proogation logic.
     *
     * @return - Boolean indicating if the updateValue changed the value or not.
     */
    updateValue(value, flags = {}) {
        if (value) {
            value = value.replace(/-/g, '/');
        }
        return super.updateValue(...arguments);
    }


    checkValidity(data, dirty, rowData) {
        //Call the super checkValidity method to perform the default input validation.
        const isValid = super.checkValidity(data, dirty, rowData);
    
        //Call the customValidation method to perform the custom validation.
        const customValidationResult = this.customValidation();
    
        //If the custom validation failed, set the error message and return false.
        if (customValidationResult !== true) {
          this.setCustomValidity(customValidationResult);
          return false;
        }
    
        //If the custom validation passed, return the result of the default input validation.
        return isValid;
      }
    
    customValidation() {
        // When 'Allow Manual Input' is allowed, 'checkValidity' reads an incorrect value.
        // These two lines always emphasize to read the last value and set it.
        const value = this.el.querySelector('input').value;
        this.setValue(value);
        
        // return if value is empty
        if (!!!value) return true;
        const valid = this.validateDate(value);
        if (typeof valid === 'string') {
            return valid;
        } else if (!valid) {
            return 'Please enter a valid date.';
        }
        return true;
    }

    validateDate(date) {
        const datePattern = /^(?!00)(0[1-9]|[12][0-9]|30)\/(?!00)(0[1-9]|1[0-2])\/(?!0000)\d{4}$/;
        if (date.match(datePattern) !== null) {
            if(typeof this.dateRangeArray !== 'undefined'){
                date = date.split('/');

                if (date.length !== 3) {
                    return "Invalid date format. Please use DD/MM/YYYY.";
                }

                const day = date[0];
                const month = date[1];
                const year = date[2];

                date = `${year}-${month}-${day}`;

                for (const range of this.dateRangeArray) {
                    if (this.isDayInRange(date, range) ) {
                        return "Invalid date. You have selected to disable the date."
                    }
                }

                
            }
            
            return true
            
        }
        
        return false

    }
    isDayInRange = function(fullDate, range) {
		const fromDate = new Date(range.from).getTime();
		const toDate = new Date(range.to).getTime();
		const currentDate = new Date(fullDate).getTime();
	  
		return currentDate >= fromDate && currentDate <= toDate;
	}
}

// add checkbox allowInput to display in form builder
HijriDateComponent.editForm = function() {
    return FieldComponent.editForm([
    {
        weight: 10,
        key:'display',
        components: [
            {
                weight: 500,
                type: 'checkbox',
                input: true,
                key: 'allowInput',
                label: 'Allow Manual Input',
                tooltip: 'Check this if you would like to allow the user to manually enter in the date.'
            }
        ]   
   },
   {
        weight: 10,
        key:'date',
        label:'Date',
        components: [
            {
                type: 'tags',
                input: true,
                key: 'datePicker.disable',
                label: 'Disable specific dates or dates by range',
                placeholder: '(yyyy-MM-dd) or (yyyy-MM-dd - yyyy-MM-dd)',
                tooltip: 'Add dates that you want to blacklist. For example: \n \n 1444-02-21',
                validate: {
                    custom: function(value) {
                        if (!value.input) {return true;} 
                        let dates_array = value.input.split(',');

                        const isValid = dates_array.every((date) => { 
                            const datePattern = /^(?!0000)\d{4}-(?!00)(0[1-9]|1[0-2])-(?!00)(0[1-9]|[12][0-9]|30)$/;
                            const dateRangePattern = /^(?!0000)\d{4}-(?!00)(0[1-9]|1[0-2])-(?!00)(0[1-9]|[12][0-9]|30) - (?!0000)\d{4}-(?!00)(0[1-9]|1[0-2])-(?!00)(0[1-9]|[12][0-9]|30)$/;
                            return date.match(datePattern) !== null || date.match(dateRangePattern) !== null;
                        }); 
                        if (!isValid) {return 'Invalid date';} 
                        return true;
                    }
                },

                weight: 21
            }
        ]   
    }
 ]);
};

Formio.use({
    components: {
        hijridate: HijriDateComponent
    }
});